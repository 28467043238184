import React from 'react';
import cn from 'classnames';
import { Flex } from 'antd';
import { NbaStatsBody } from 'src/types';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import OverUnderDisplay from './OverUnderDisplay';

import './MarketTableStats.scss';

function round(num: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}
function MarketTableStats({
  body,
  stats,
  compact = false,
  showOverUnder = true,
  streak,
}: {
  body: NbaStatsBody;
  stats: any;
  compact?: boolean;
  showOverUnder?: boolean;
  streak?: number;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  if (!stats) return <></>;

  const sortedKeys = ['all', 'home', 'away', 'vs'].filter(
    k => Object.keys(stats).includes(k) && stats[k].stats.length > 0
  );

  const marketContainerCss = cn('market-table-stats__container', {
    'market-table-stats__container--compact': compact,
    'market-table-stats__container--mobile': isTabletOrMobile,
  });

  return (
    <Flex
      vertical={compact || isTabletOrMobile}
      align="center"
      gap={compact || isTabletOrMobile ? 8 : 16}
      justify="space-between"
      className={marketContainerCss}
    >
      {showOverUnder && <OverUnderDisplay compact={compact} overUnder={body?.overUnder} threshold={body?.threshold} />}
      <Flex align="center" style={{ width: '100%' }}>
        {Object.keys(stats).length === 0 ? (
          <>No history</>
        ) : (
          <Flex>
            {streak ? (
              <div
                className="market-table-stats__streak"
                style={{ background: streak > 3 ? '#50853a' : streak > 1 ? '#0f50d4' : '#cd201f' }}
              >
                <div className="market-table-stats__streak-header">HIT STREAK</div>
                <div className="market-table-stats__streak-number">{streak} game(s)</div>
              </div>
            ) : null}
            <>
              {sortedKeys.map((statKey: string) => {
                const containerCss = cn('market-table-stats__percent-container', {
                  'market-table-stats__percent-container--compact': compact,
                  'market-table-stats__percent-container--mobile': isTabletOrMobile,
                  // @ts-ignore
                  'market-table-stats__percent-container--negative': stats[statKey]?.percentage < 50,
                });

                return (
                  <div
                    key={`table-${body.playerId}-${body?.overUnder}-${body?.threshold}-${statKey}`}
                    className={containerCss}
                  >
                    <div className="market-table-stats__percent-header">
                      {statKey}
                      {/* {statKey === 'vs' ? `(${stats[statKey].stats.length})` : ''} */}
                    </div>
                    {/* @ts-ignore */}
                    <div className="market-table-stats__percent">
                      {round(stats[statKey]?.percentage, 1)}
                      <span style={{ fontSize: '.85em' }}>%</span>
                    </div>
                  </div>
                );
              })}
            </>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default MarketTableStats;
