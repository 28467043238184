import React from 'react';
import { Flex, Button, Tag } from 'antd';
import { ArrowRightOutlined, StarFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { MlbTeam, OddsEvent } from 'src/types';
import { useGetMlbTeamsQuery, useGetMlbGameOddsQuery } from 'src/redux/queries/mlbApi';
import { PicksRecord } from '../OurPicks/OurPicks';
import { getTeam } from '../utils/getTeam';

import './BettingEvent.scss';

export const AmericanLeague = {
  mlbLogo1: '../../../logos/mlb_american_league_logo.svg',
  espnLogo1: '../../../logos/mlb_american_league_logo.svg',
  teamAbv: 'AL',
  teamName: 'American League',
  teamCity: 'American',
  wins: '0',
  loss: '0',
};

export const NationalLeague = {
  mlbLogo1: '../../../logos/mlb_national_league_logo.png',
  espnLogo1: '../../../logos/mlb_national_league_logo.png',
  teamAbv: 'NL',
  teamName: 'National League',
  teamCity: 'National',
  wins: '0',
  loss: '0',
};

// const getTeam = (teams: MlbTeam[] | undefined, teamName: string): MlbTeam | null => {
//   return (
//     (teams || []).find((team: MlbTeam) => {
//       return (
//         `${team.teamCity} ${team.teamName}`.toLocaleLowerCase().replace('.', '') ===
//         teamName.toLocaleLowerCase().replace('.', '')
//       );
//     }) || null
//   );
// };
function MLBEvent({
  event,
  handleNavigate,
  picks,
}: {
  event: OddsEvent;
  handleNavigate: (eventId: string) => void;
  picks: PicksRecord[];
}) {
  const { data: teams } = useGetMlbTeamsQuery();

  const { data: gameOdds } = useGetMlbGameOddsQuery(
    { gameDate: dayjs(event.commence_time).format('YYYYMMDD') },
    { skip: !event.commence_time }
  );

  let homeTeam: Partial<MlbTeam> | null = getTeam(teams, event.home_team);
  let awayTeam: Partial<MlbTeam> | null = getTeam(teams, event.away_team);

  const gameKey =
    gameOdds && gameOdds.body
      ? Object.keys(gameOdds.body).find(key => key.includes(`${awayTeam?.teamAbv}@${homeTeam?.teamAbv}`)) || null
      : null;

  const impliedTotals = gameKey ? gameOdds.body[gameKey] : null;

  if (event.home_team === 'American League') {
    homeTeam = AmericanLeague;
    awayTeam = NationalLeague;
  } else if (event.home_team === 'National League') {
    awayTeam = AmericanLeague;
    homeTeam = NationalLeague;
  }

  const Team = ({
    team,
    isHome,
    minus,
    total,
  }: {
    team: MlbTeam;
    isHome: boolean;
    minus: string | null;
    total: string | null;
  }) => {
    const logoCss = cn('betting__event-logo-avatar', {
      'betting__event-logo-avatar--home': isHome,
    });

    return (
      <div>
        <Flex align="flex-start">
          <div className="betting__event-logo">
            <div className={logoCss}>
              <div>
                <img src={team?.mlbLogo1} alt={team?.teamAbv} />
              </div>
              {minus && <div className="betting__event-plusminus">{minus}</div>}
              {total && <div className="betting__event-team-total">{total}</div>}
            </div>
            <div className="betting__event-team-wrapper">
              <div className="betting__event-team-name">
                {team?.teamName} <span className="city">{team?.teamCity}</span>
              </div>
              <div className="betting__event-win-loss">
                {team?.wins} - {team?.loss}
              </div>
            </div>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <div className="betting__event-wrapper">
      <div className="betting__event betting__event--secondary">
        <Flex align="center" justify="space-between">
          <Flex align="flex-start" vertical={true} justify="space-between">
            <Team
              team={homeTeam as MlbTeam}
              isHome
              minus={+impliedTotals?.draftkings.homeTeamMLOdds > 0 ? impliedTotals?.draftkings.totalUnder : null}
              total={+impliedTotals?.draftkings.homeTeamMLOdds < 0 ? impliedTotals?.draftkings.homeTeamMLOdds : null}
            />
            <Team
              team={awayTeam as MlbTeam}
              isHome={false}
              minus={+impliedTotals?.draftkings.awayTeamMLOdds > 0 ? impliedTotals?.draftkings.totalUnder : null}
              total={+impliedTotals?.draftkings.awayTeamMLOdds < 0 ? impliedTotals?.draftkings.awayTeamMLOdds : null}
            />
          </Flex>
          <Flex gap={8} vertical={true} align="center" justify="space-between">
            <Flex className="betting__event-start-time" gap={8}>
              <div>{dayjs(event.commence_time).format('h:mm A')}</div>
              {picks.length > 0 && <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}></Tag>}
            </Flex>
            <Flex gap={8} align="center">
              <Button
                onClick={() => handleNavigate(event.id)}
                icon={<ArrowRightOutlined />}
                shape="round"
                type="primary"
                iconPosition="end"
              >
                Props
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default MLBEvent;
