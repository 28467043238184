import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Flex, Button, Tag, Modal } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { userApi, useGoToPaymentPageMutation } from 'src/redux/queries/userApi';
import qs from 'qs';
import { useGetUserInfo } from 'src/hooks/useGetUser';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY, BOOKMAKER_LOGOS, BOOKMAKER_NAMES } from '../../constants';
import './Pricing.scss';

const formattedNumber = (number: number) => number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

function Pricing() {
  const { user } = useGetUserInfo();
  const { search } = useLocation();
  const [goToPaymentPage] = useGoToPaymentPageMutation();
  const { data: plansData, isLoading: plansIsLoading } = userApi.useGetStripePlansQuery();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const [booksModalIsOpen, setBooksModalIsOpen] = useState(false);

  const parsedQuery = qs.parse(search.substring(1, search.length)) as Record<string, string>;

  if (plansIsLoading) return null;

  if (parsedQuery.subscribed) {
    userApi.util.invalidateTags(['User']);
    userApi.util.resetApiState();
    return (
      <Flex gap={16} align="center" justify="center" vertical={true} style={{ paddingTop: 48 }}>
        <CheckCircleOutlined style={{ color: 'green', fontSize: 60 }} />
        <div>Thank you for your support.</div>
        <div>We hope you win big!</div>
      </Flex>
    );
  }

  const plans: {
    isBestValue: boolean;
    title: string;
    description?: string;
    myPlan: boolean;
    prices?: Record<'monthly' | 'yearly', { priceId: string; price: number }>;
    features: (React.ReactNode | string)[];
    extras: (React.ReactNode | string)[];
  }[] = [
    {
      isBestValue: false,
      title: 'Free',
      myPlan: !user.basicPlan && !user.proPlan,
      features: ['Research Tool', 'NFL only', '3 Game stat history'],
      extras: [],
    },
    {
      isBestValue: false,
      title: 'Basic',
      myPlan: user.basicPlan,
      description: 'Both plans include a 3-day free trial.',
      prices: {
        monthly: {
          priceId: plansData?.basic.monthly.id,
          price: plansData?.basic.monthly.price,
        },
        yearly: {
          priceId: plansData?.basic.yearly.id,
          price: plansData?.basic.yearly.price,
        },
      },
      features: ['Research Tool'],
      extras: [
        'All sports (NFL/NBA/NHL/MLB)',
        <>
          <strong>20</strong> Game stat history
        </>,
        'Home stats',
        'Away stats',
        'vs Opponent stats',
      ],
    },
    {
      isBestValue: false,
      title: 'Pro',
      myPlan: user.proPlan,
      description: 'Both plans include a 3-day free trial.',
      prices: {
        monthly: {
          priceId: plansData?.pro.monthly.id,
          price: plansData?.pro.monthly.price,
        },
        yearly: {
          priceId: plansData?.pro.yearly.id,
          price: plansData?.pro.yearly.price,
        },
      },
      features: [
        'Research Tool',
        'All sports (NFL/NBA/NHL/MLB)',
        <>
          <strong>20</strong> Game stat history
        </>,
        'Home stats',
        'Away stats',
        'vs Opponent stats',
      ],
      extras: [
        <Flex align="center" gap={8}>
          <div>
            <strong>Betting lines</strong>
          </div>
          <div>
            <Button
              size="small"
              type="primary"
              shape={'circle'}
              icon={<InfoCircleOutlined />}
              onClick={() => setBooksModalIsOpen(true)}
            />
          </div>
        </Flex>,
        <>
          <strong>Daily Pick Alerts</strong>
        </>,
        <>
          <strong>Our Line Ratings</strong>
        </>,
        <>
          <strong>Our Daily Betting Picks</strong>
        </>,
      ],
    },
  ];

  const goToPaymentLink = async (priceId: string) => {
    const { url } = await goToPaymentPage({ priceId }).unwrap();
    window.location.href = url;
  };

  const renderPrice = ({
    price,
    label,
    priceId,
    myPlan,
  }: {
    price?: number;
    label: string;
    priceId?: string;
    myPlan: boolean;
  }) => {
    if (price === undefined) return null;

    return (
      <div className="pricing__card-price">
        <div className="pricing__card-price-label">{label}</div>
        <div className="pricing__card-price-price">{formattedNumber(price)}</div>
        <Button disabled={myPlan} block type="primary" size="large" onClick={() => goToPaymentLink(priceId as string)}>
          Subscribe
        </Button>
      </div>
    );
  };

  return (
    <div className="pricing__container">
      <Flex vertical={true} align="center" justify="center" style={{ marginBottom: 16 }}>
        <div className="pricing__title">Start Winning Today</div>
        <div className="pricing__title pricing__title--subtitle">Pick your Plan</div>
      </Flex>
      <Flex
        vertical={isTabletOrMobile}
        gap={16}
        style={{ marginBottom: 16, width: '100%', justifyContent: 'space-evenly' }}
      >
        {plans.map((plan, index) => (
          <div className="pricing__card" key={index}>
            {plan.title !== 'Free' && <div className="pricing__card-top-border" />}
            {plan.isBestValue && <div className="pricing__card-best-value">Best Value</div>}
            <div className="pricing__card-title">
              <div className="pricing__card-title-title">
                <Flex align="center" justify="center" gap={8}>
                  <div>
                    <strong>{plan.title}</strong> Access Plan{' '}
                  </div>
                  <div>{plan.myPlan ? <Tag color="#87d068">Your Plan</Tag> : ''}</div>
                </Flex>
              </div>
              {plan.description && <div className="pricing__card-title-description">{plan.description}</div>}
            </div>
            <Flex justify="space-between" align="center" style={{ alignContent: 'center' }}>
              {renderPrice({
                myPlan: plan.myPlan,
                priceId: plan.prices?.['monthly'].priceId,
                price: plan.prices?.['monthly'].price,
                label: 'Monthly',
              })}
              {renderPrice({
                myPlan: plan.myPlan,
                priceId: plan.prices?.['yearly'].priceId,
                price: plan.prices?.['yearly'].price,
                label: 'Yearly',
              })}
            </Flex>
            <div className="pricing__card-features">
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <div>
                      <CheckCircleOutlined style={{ fontSize: 18, color: 'green' }} />
                    </div>
                    <div>{feature}</div>
                  </li>
                ))}
              </ul>
            </div>
            {plan.extras.length > 0 && (
              <div className="pricing__card-extras">
                <ul>
                  {plan.extras.map((feature, index) => (
                    <li key={index}>
                      <div>
                        <CheckCircleOutlined style={{ fontSize: 18, color: 'green' }} />
                      </div>
                      <div>{feature}</div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {plan.title !== 'Free' && <div className="pricing__card-bottom-border" />}
          </div>
        ))}
      </Flex>
      <Modal
        title="Supported Books"
        open={booksModalIsOpen}
        onOk={() => {}}
        onCancel={() => setBooksModalIsOpen(false)}
        footer={null}
      >
        <Flex gap={16} align="center" style={{ flexWrap: 'wrap', paddingTop: 16 }}>
          {Object.keys(BOOKMAKER_NAMES)
            .filter((key: string) => key !== 'lowvig')
            .map((key: string) => {
              return (
                <Flex align="center" gap={8} className="pricing__bookmaker-option">
                  <div>
                    <img src={`../../../logos/bookmakers/${BOOKMAKER_LOGOS[key as string]}`} alt={key as string} />
                  </div>
                  <div>{key}</div>
                </Flex>
              );
            })}
        </Flex>
      </Modal>
      <div className="pricing__note">
        *Cancel any of our paid plans at any time.
        <br />
        **All prices shown are in USD
      </div>
    </div>
  );
}

export default Pricing;
