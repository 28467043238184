import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnmountClosed } from 'react-collapse';
import { Sport, NflPlayer } from '../../../types';
import { useGetNflTeamsQuery, useGetNflPlayersQuery, useGetNflGameWeatherQuery } from 'src/redux/queries/nflApi';
import { PlayerMarkets } from '../utils/processSportsMarkets';
import MarketTeams from '../MarketTeams';
import NFLPlayerHeader from '../Players/NFLPlayerHeader';
import NFLPlayerMarket from './NFLPlayerMarket';
import { useGetSportsMarketsQuery } from '../../../redux/queries/bettingApi';
import { Button, Flex, Spin } from 'antd';
import NoOfGamesDumb from '../../PlayerTrends/NoOfGames_Dumb';
import { normalizeName, findPlayerByName } from '../utils/normalizeText';
import { PicksRecord } from '../OurPicks/OurPicks';
import { getTeam } from '../utils/getTeam';

import './MLBPlayerMarket.scss';

const PAGE_COUNT = 10;
const LOAD_COUNT = 10;

// const getTeam = (teams: NflTeam[] | undefined, teamName: string): NflTeam | null => {
//   return (
//     (teams || []).find((team: NflTeam) => {
//       return (
//         `${team.teamCity} ${team.teamName}`.toLocaleLowerCase().replace('.', '') ===
//         teamName.toLocaleLowerCase().replace('.', '')
//       );
//     }) || null
//   );
// };

export default function NFLMarket({
  homeTeam,
  awayTeam,
  startTime,
  playerMarkets,
  picks,
}: {
  homeTeam: string;
  awayTeam: string;
  startTime: string;
  playerMarkets: PlayerMarkets;
  picks: PicksRecord[];
}) {
  const { sportId } = useParams();
  const { data: players, isFetching: isFetchingPlayers } = useGetNflPlayersQuery();
  const { data: markets } = useGetSportsMarketsQuery({ sport: sportId as Sport });
  const { data: teams } = useGetNflTeamsQuery();
  const [pager, setPager] = useState<number>(PAGE_COUNT);
  const [noOfGames, setNoOfGames] = useState<number>(6);
  const handlePager = () => {
    if (pager < Object.keys(playerMarkets).length) {
      setPager(pager + LOAD_COUNT);
    }
  };

  const [isExpanded, setExpanded] = useState<Record<string, boolean>>({});

  const home_team = getTeam(teams, homeTeam);
  const away_team = getTeam(teams, awayTeam);

  const { data: weather } = useGetNflGameWeatherQuery(
    { homeTeam: home_team?.teamAbv as string, eventDateTime: startTime },
    { skip: !home_team?.teamAbv }
  );

  useEffect(() => {
    if (Object.keys(playerMarkets ?? {}).length === 0) return;

    setTimeout(() => {
      setExpanded({
        [Object.keys(playerMarkets)[0]]: true,
      });
    }, 1000);
  }, [playerMarkets]);

  if (isFetchingPlayers)
    return (
      <Flex align="center" justify="center" style={{ height: 200 }}>
        <Spin size="large" />
      </Flex>
    );

  return (
    <div>
      <MarketTeams
        homeTeam={home_team}
        awayTeam={away_team}
        startTime={startTime}
        weather={weather?.hasForecast ? weather : undefined}
      />
      <div style={{ paddingBottom: 16 }}>
        {/* <NoOfGamesDumb noOfGames={noOfGames} handleSetNoOfGames={setNoOfGames} /> */}
        {Object.keys(playerMarkets)
          .slice(0, pager)
          .map(playerName => {
            const player = findPlayerByName(playerName, players, home_team?.teamAbv, away_team?.teamAbv);
            if (!player) return null;

            const playerPicks = picks.filter(
              p =>
                p.playerId === player.playerID &&
                Object.keys(playerMarkets[normalizeName(playerName)]).some(m => m === p.marketName)
            );

            return (
              <div key={playerName} className="mlb-player-market__container">
                <NFLPlayerHeader
                  player={player as NflPlayer}
                  handleExpandSection={e => {
                    e.stopPropagation();
                    setExpanded(prevExpanded => {
                      prevExpanded[playerName] = !prevExpanded[playerName];
                      return { ...prevExpanded };
                    });
                  }}
                  isExpanded={isExpanded[playerName]}
                  hasPicks={playerPicks.length > 0}
                />
                <UnmountClosed isOpened={isExpanded[playerName]}>
                  {Object.keys(playerMarkets[normalizeName(playerName)]).map(marketName => {
                    const market = markets?.find(m => m.key === marketName);

                    const marketPicks = playerPicks.filter(p => p.marketName === marketName);

                    return (
                      <NFLPlayerMarket
                        key={marketName}
                        player={player as NflPlayer}
                        bettingMarkets={playerMarkets[normalizeName(playerName)][marketName]}
                        market={market}
                        opponent={
                          player.team === home_team?.teamAbv
                            ? (away_team?.teamAbv as string)
                            : (home_team?.teamAbv as string)
                        }
                        isHome={player.team === home_team?.teamAbv}
                        homeTeam={home_team?.teamAbv as string}
                        eventDateTime={startTime}
                        noOfGames={noOfGames}
                        hasPicks={marketPicks.length > 0}
                      />
                    );
                  })}
                </UnmountClosed>
              </div>
            );
          })}
        {Object.keys(playerMarkets).length > 0 &&
        Object.keys(playerMarkets).length > PAGE_COUNT &&
        pager < Object.keys(playerMarkets).length ? (
          <Button shape="round" type="primary" onClick={handlePager}>
            Load More
          </Button>
        ) : Object.keys(playerMarkets).length === 0 ? (
          <div>No lines at this time.</div>
        ) : null}
      </div>
    </div>
  );
}
