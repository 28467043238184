import { MlbPlayer, NflPlayer, NbaPlayer, NhlPlayer } from '../../../types';
import { Sport } from '../../../../server/types';

const nameMapping = (playerName: string, team: string | null = null): string => {
  const mapping: Record<string, string> = {
    'J. D. Martinez': 'J.D. Martinez',
    'Jonny Deluca': 'Jonny DeLuca',
    'AJ Brown': 'A.J. Brown',
    'Deebo Samuel': 'Deebo Samuel Sr.',
    'Darius Slay': 'Darius Slay Jr.',
    'Scott Miller': 'Scotty Miller',
    'Brian Thomas Jr': 'Brian Thomas Jr.',
    'Trent Sherfield': 'Trent Sherfield Sr.',
    'Laviska Shenault Jr': 'Laviska Shenault Jr.',
    'Luis Ortiz': team === 'PIT' ? 'Luis L. Ortiz' : 'Luis F. Ortiz',
    'Kevin Byard': 'Kevin Byard III',
    'Chigoziem Okonkwo': 'Chig Okonkwo',
    'JT Ginn': 'J.T. Ginn',
    'Justin Madubuike': 'Nnamdi Madubuike',
    'Ernest Jones': 'Ernest Jones IV',
    // 'Randy Vasquez': '',
    // 'Emerson Hancock': '',
    'Erick All': 'Erick All Jr.',
    // 'Cole Caufield': '',
    // 'Juraj Slafkovsky': '',
    // 'Mike Matheson': '',
  };
  if (mapping[playerName]) return mapping[playerName];

  return playerName;
};

export const normalizeName = (name: string): string => {
  return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const findPlayerByName = (
  playerName: string,
  players: MlbPlayer[] | NflPlayer[] | NbaPlayer[] | NhlPlayer[] | undefined,
  homeTeam?: string,
  awayTeam?: string
): MlbPlayer | NflPlayer | NbaPlayer | NhlPlayer | null => {
  const player = players?.find(p => {
    if (homeTeam && awayTeam)
      return (
        (normalizeName(p.longName).toLowerCase() === normalizeName(playerName).toLowerCase() ||
          normalizeName(p.longName).toLowerCase() === normalizeName(nameMapping(playerName, p.team)).toLowerCase()) &&
        (p.team === homeTeam || p.team === awayTeam)
      );

    return (
      normalizeName(p.longName).toLowerCase() === normalizeName(playerName).toLowerCase() ||
      normalizeName(p.longName).toLowerCase() === normalizeName(nameMapping(playerName, p.team)).toLowerCase()
    );
  });
  if (!player) {
    console.log(`[NOTFOUND]${playerName}`);
    return null;
  }

  return player;
};
