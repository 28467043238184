import { baseApi } from './baseApi';
import { OddsSport, OddsEvent, OddsMarkets, Sport, OddsSportsMarket, PicksBookmaker } from '../../types';

export const bettingApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getOddsSports: build.query<OddsSport[], void>({
      query: () => `/odds/sports`,
    }),
    getOddsSportEvents: build.query<OddsEvent[], { sportsKey: string }>({
      query: ({ sportsKey }) => `/odds/sports/${sportsKey}/events`,
    }),
    getSportEventOdds: build.query<OddsMarkets, { sportsKey: string; eventId: string }>({
      query: ({ sportsKey, eventId }) => `/odds/sports/${sportsKey}/events/${eventId}/odds`,
    }),
    getSportsMarkets: build.query<OddsSportsMarket[], { sport: Sport }>({
      query: ({ sport }) => `/odds/sports/${sport}/markets`,
    }),
    getPicksBookmakers: build.query<PicksBookmaker[], { sport: Sport }>({
      query: ({ sport }) => `/odds/sports/${sport}/picks/bookmakers`,
    }),
    getOurPicks: build.query<any[], { sport: Sport; bookmakers?: string[]; noOfPicks: number }>({
      query: ({ sport, bookmakers, noOfPicks }) => {
        return {
          url: `/odds/sports/${sport}/picks`,
          method: 'POST',
          body: {
            bookmakers: bookmakers ? bookmakers : [],
            noOfPicks,
          },
        };
      },
      providesTags: ['Picks'],
      keepUnusedDataFor: 30,
    }),
    getEventPicks: build.query<any[], { eventId: string }>({
      query: ({ eventId }) => `/odds/events/${eventId}/picks`,
    }),
  }),
});

export const {
  useGetSportsMarketsQuery,
  useGetOddsSportsQuery,
  useLazyGetOddsSportEventsQuery,
  useGetOddsSportEventsQuery,
  useGetSportEventOddsQuery,
  useGetOurPicksQuery,
  useLazyGetOurPicksQuery,
  useGetPicksBookmakersQuery,
  useGetEventPicksQuery,
} = bettingApi;
